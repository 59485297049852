<template>
	
    <div class="footerWrap">
		<div class="innerWrap">
			<div>
				<p class="mOff">서울특별시 구로구 디지털로31길 38-21, 701호 (구로동, 이앤씨벤처드림타워3차) </p>
				<p class="mOff">TEL :  02-858-3001 <span class="guideLine">|</span>   <span class="guideLine">|</span> MAIL : support@aprocni.com</p>
				<p>Copyright ⓒ (주)디엑스플랫폼 All rights reserved.</p>
				<p class="b mt5"><a href="javascript:;" style="color:#fff;" @click="moveTo">개인정보 처리방침</a></p> 
				<img src="/img/comm/btmLogo.png" alt="">
			</div>
		</div>
	</div>
	
</template>

<script>

import { useRouter, useRoute } from 'vue-router';

export default {
  name: 'Footer',
  props: {
    msg: String
  },

  setup(){

	  const router = useRouter();
	  const moveTo = () => {
		  router.push({path : '/privacy'});
	  }



	  return {moveTo};
  }
  
}
</script>


