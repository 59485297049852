<template>
  <div id="app" class="wrap">
    <Header />
    <router-view >
     
    </router-view>
    <Footer />
  </div>  
  
</template>

<script>

import Header from './components/layout/Header.vue';
import Footer from './components/layout/Footer.vue';

export default {
  name: 'App',
  props: {
    msg: String
  },
  components: {
    Header,
    Footer,
  },
  

  


}
</script>

<style>

</style>
