
import { createStore } from "vuex";

const store = createStore({
    state(){
        return {
            name : 'k'
        }
    }
})

export default store;