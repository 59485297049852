<template>
	<div class="headerWrap" id="Header" :class="{'down' : top.height > 30 || route.name != 'Home'}" ref="headerElement">
		<div class="innerWrap" >
			<a href="#" class="logo" @click="moveTo('E')"></a>
			<a href="javascript:;" onclick="$('#menuAll').show(); $('.menuBack').show();" class="pOff btn_common btn_header_home03 menuBtn"><i class="fas fa-bars"></i></a>
			<div class="menuBack open"></div>
			<div id="menuAll" style="display:none;">
				<div class="xDiv" style="">
					<a href="javascript:;" class="vmclose" onclick="$('#menuAll').hide(); $('.menuBack').hide();">
						<i class="fas fa-times"></i>
					</a>
				</div>
				<ul class="mobile mgnb" style="">
					<li style=""><a href="javascript:;" @click="moveTo('D')" class="">로그인</a></li>
					<li style=""><a href="javascript:;" @click="moveTo('A')" :class="addClass('A')">주요기능</a></li>
					<li><a href="javascript:;"  @click="moveTo('B')" :class="addClass('B')">도입문의</a></li>
					<li><a href="javascript:;" @click="moveTo('C')" :class="addClass('C')">이용지원</a></li>
				</ul>
			</div>
			<ul class="mOff gnb">
				<li style=""><a href="javascript:;" @click="moveTo('A')" :class="addClass('A')">주요기능</a></li>
				<li><a href="javascript:;" @click="moveTo('B')" :class="addClass('B')">도입문의</a></li>
				<li><a href="javascript:;" @click="moveTo('C')" :class="addClass('C')">이용지원</a></li>
				<li style=""><a href="javascript:;" @click="moveTo('D')" class="btn btnBasic">로그인</a></li>
			</ul>
		</div>
	</div>	
		
</template>

<script>
import { onMounted, reactive, ref } from '@vue/runtime-core';
import { useRouter, useRoute } from 'vue-router';

export default {
  name: 'Header',
  props : [],
  setup(){
	const router = useRouter();
	const route = useRoute();
	const headerElement = ref(null);
	const top = reactive({height : 0});
	const menu = reactive([
		{type : 'A', url : '/merit'},
		{type : 'B', url : '/inquiry'},
		{type : 'C', url : '/support'},
		{type : 'D', url : '/login'},
		{type : 'E', url : '/'},
	]);

	const scrollEvent = () => {
		top.height = window.scrollY;
	};

	const moveTo = (type) => {
		const url = menu.filter(item => item.type == type).map(item2 => item2.url)[0];
		
		if(url){

			if(url == '/login'){
				//location.href = "https://aptok.co.kr";
				window.open("https://aptok.co.kr");

			}else{
				router.push({path : url});
			}
		}
		
	};

	const addClass = (type) => {
		const url = menu.filter(item => item.type == type).map(item2 => item2.url)[0];

		const path = router.currentRoute.value.path;

		if(url == path){
			return "on";
		}else{
			return "";
		}
	};

	onMounted(() => {
      window.addEventListener('scroll', scrollEvent);
	  
    });
	  
	return {top, scrollEvent, headerElement, moveTo, route, addClass}
  }
}
</script>


