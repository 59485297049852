import { createWebHistory, createRouter } from "vue-router";

const routes = [

    {
        path : '/',
        name : 'Home',
        component : () => import('./views/Home.vue')
    },

    {
        path : '/merit',
        name : 'Merit',
        component : () => import('./views/Merit.vue')
    },

    {
        path : '/inquiry',
        name : 'Inquiry',
        component : () => import('./views/Inquiry.vue')
    },

    {
        path : '/support',
        name : 'Support',
        component : () => import('./views/Support.vue')
    },

    {
        path : '/privacy',
        name : 'Privacy',
        component : () => import('./views/Privacy.vue')
    },

    {
        path: '/:pathMatch(.*)*',
        redirect: "/"
    },

    //404를 포함한 예외는 그냥 홈으로 보냄
    
   
];

const router = createRouter({
    history : createWebHistory(),
    routes,
    scrollBehavior(to, from, savedPosition){ 
        return {top:0};
    }
});

export default router;